import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Badge } from 'reactstrap';

import Button from '@material-ui/core/Button';
import arrayMutators from 'final-form-arrays';
import { Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import async from 'async';
import { Link } from 'react-router-dom';

import IntlMessages from 'utils/IntlMessages';
import { injectIntl } from 'react-intl';
import ServiceService from 'services/Service';
import BundleService from 'services/Bundle';
import SettingsActions from 'store/reducers/Settings';
import Util from 'utils/Util';
import { Switch } from 'components/custom/FormElements';
import HoverTitle from 'components/custom_v2/HoverTitle';
import ConfirmationDialog from 'components/custom_v2/ConfirmationDialog';
import HeaderImage from 'assets/images/kotler/sectionHeaders/services.jpeg';
import KotlerSectionHeader from 'components/custom/KoTlerSectionHeader';
import SectionInfoText from 'components/custom/SectionInfoText';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import KotlerTableTitle from 'components/custom/KotlerTableTitle';
import FeatherIcon from 'components/custom/FeatherIcon';
import HeadConfig from 'components/custom/HeadConfig';
import { STATUS } from 'modules/sessions';

class Service extends React.Component {
  state = {
    fieldSwitch: '',
    fieldSwitchVal: '',
    services: [],
    bundles: [],
    confirmSaveModal: false,
    confirmExitModal: false,
    confirmRememberModal: false,
    openedProducts: [],
  };
  confirmLessFivePercentService = [];
  boolConfirmLessFivePercentService = false;
  confirmLessFivePercentBundle = [];
  boolConfirmLessFivePercentBundle = false;
  fieldModified = false;
  canExit = false;
  goTo = '/';
  componentDidMount() {
    const { toggleAlert, user } = this.props;
    if ( !user.activeSession ) {
      return toggleAlert( 'ERROR' );
    }

    this.activeSession = user.activeSession;
    this.license = _.find( user.licenses, { session: { id: user.activeSession.id } } );
    if (
      !user.activeSession.returnToken
      && ( !this.license || !this.license.workGroup )
    ) {
      return toggleAlert( 'ERROR' );
    }

    this.getData();

    const jrmenuButton = document.getElementsByClassName(
      'd-flex align-items-center',
    );
    jrmenuButton[0].addEventListener(
      'click',
      e => this.handleOnclick( e, { boton: 'menu' } ),
      false,
    );
    const perfilButton = document.getElementsByClassName(
      'list-inline-item user-nav',
    );
    perfilButton[0].addEventListener(
      'click',
      e => this.handleOnclick( e, { boton: 'menu' } ),
      false,
    );
    perfilButton[1].addEventListener(
      'click',
      e => this.handleOnclick( e, { boton: 'menu' } ),
      false,
    );
    document
      .getElementById( 'navigation-menu-dropdown' )
      .addEventListener(
        'click',
        e => this.handleOnclick( e, { boton: 'menu' } ),
        false,
      );
    const Anchors = document.getElementsByTagName( 'a' );

    for ( let i = 0; i < Anchors.length; i += 1 ) {
      Anchors[i].addEventListener(
        'click',
        e => this.handleOnclick( e, Anchors[i] ),
        false,
      );
    }
  }

  componentWillUnmount() {
    this.fieldModified = false;
  }

  toggleConfirmExitModal = () => {
    const { confirmExitModal } = this.state;
    this.setState( { confirmExitModal: !confirmExitModal } );
  };

  handleOnclick = ( e, obj ) => {
    if ( this.fieldModified ) {
      e.preventDefault();
      e.stopPropagation();
      if ( obj.href ) {
        this.goTo = obj.href;
      } else {
        this.goTo = '';
      }
      this.toggleConfirmExitModal();
    }
  };
  setConfirmExitModal = () => {
    const { history } = this.props;
    this.canExit = true;
    if ( this.goTo === '' ) {
      this.fieldModified = false;
    } else {
      const path = new URL( this.goTo );
      history.push( path.pathname );
    }
  };

  setChangeStatus = () => {
    this.fieldModified = true;
  };

  getData = () => {
    const { toggleAlert, toggleLoading } = this.props;

    toggleLoading( true );
    async.parallel(
      {
        services: ( cb ) => {
          ServiceService.getServicesV2( this.activeSession.id, {
            workgroup: this.license.workGroup.id,
            limit: -1,
          } ).then( ( response ) => {
            if ( !response.ok ) return cb( response.errors );
            cb( null, response.data.data );
          } );
        },
        bundles: ( cb ) => {
          BundleService.getBundlesV2( this.activeSession.id, {
            workgroup: this.license.workGroup.id,
            limit: -1,
          } ).then( ( response ) => {
            if ( !response.ok ) return cb( response.errors );
            cb( null, response.data.data );
          } );
        },
      },
      ( error, results ) => {
        toggleLoading( false );
        if ( error ) return toggleAlert( error );
        this.setState( results );
      },
    );
  };

  submitForm = ( formData, FormOptions ) => {
    const { dirty, dirtyFields } = FormOptions.getState();
    const { toggleAlert, toggleLoading, intl } = this.props;

    toggleLoading( true );
    async.parallel(
      [
        ( cb ) => {
          if ( formData && formData.services.length !== 0 ) {
            if ( dirty && dirtyFields.services ) this.sendDataServices( formData.services, cb );
            else cb( null, 'NoChangesForSave' );
          } else cb( null, 'NoDataToSend' );
        },
        ( cb ) => {
          if ( formData && formData.bundles.length !== 0 ) {
            if ( dirty && dirtyFields.bundles ) this.sendDataBundles( formData.bundles, cb );
            else cb( null, 'NoChangesForSave' );
          } else cb( null, 'NoDataToSend' );
        },
      ],
      ( error, result ) => {
        let oneDataSaved = false;
        let noChangesCounter = 0;
        _.map( result, ( item ) => {
          switch ( item ) {
          case 'DataSaved':
            oneDataSaved = true;
            break;
          case 'NoChangesForSave':
            noChangesCounter += 1;
            break;
          case 'NoDataToSend':
            break;
          case 'ERROR_MESSAGE_THIS_WORKGROUP_DOES_NOT_HAVE_ENOUGH_MARKETING_BUDGET':
            toggleAlert(
              `${intl.formatMessage( {
                id:
                    'ERROR_MESSAGE_THIS_WORKGROUP_DOES_NOT_HAVE_ENOUGH_MARKETING_BUDGET',
              } )}services`,
              'error',
            );
            break;
          default:
            toggleAlert( item, 'error' );
          }
        } );

        if ( oneDataSaved ) {
          this.getData();
          toggleAlert( 'dataSaved', 'info' );
          this.fieldModified = false;
          // regular expresion if services is activating
          const re = /^services\[(.)\].active/i;
          if (
            dirtyFields.services
            && Object.keys( dirtyFields ).reduce( ( prev, value ) => {
              if ( prev ) return prev;
              if ( value.match( re ) ) return _.get( formData, value, false );
              return false;
            }, false )
          ) {
            this.toggleConfirmRememberModal();
          }
        } else if ( noChangesCounter >= 2 ) {
          toggleAlert( 'NoChangesForSave', 'info' );
          this.fieldModified = false;
        }
        toggleLoading( false );
      },
    );
  };

  sendDataServices = ( services, cb ) => {
    const priceStatus = [];
    _.map( services, ( service ) => {
      const data = {
        service: service.id,
        active: service.active,
        price: service.price,
        tradeSalePrice: service.tradeSalePrice,
      };
      priceStatus.push( data );
    } );

    const dataToSend = {
      workgroup: this.license.workGroup.id,
      priceStatus,
    };
    ServiceService.updateServicesV2( this.activeSession.id, dataToSend ).then(
      ( response ) => {
        if ( !response.ok ) {
          cb( null, response.errors );
        } else cb( null, 'DataSaved' );
      },
    );
  };

  sendDataBundles = ( bundles, cb ) => {
    const priceStatus = [];
    _.map( bundles, ( bundle ) => {
      const data = {
        bundle: bundle.id,
        active: bundle.active,
        price: bundle.monthlyFee,
        tradeSalePrice: bundle.tradeSalePrice,
        monthlyFee: bundle.monthlyFee,
        monthlyTradeSaleFee: bundle.tradeSalePrice,
      };
      priceStatus.push( data );
    } );

    const dataToSend = {
      workgroup: this.license.workGroup.id,
      priceStatus,
    };
    BundleService.updateBundlesV2( this.activeSession.id, dataToSend ).then(
      ( response ) => {
        if ( !response.ok ) cb( null, response.errors );
        else {
          this.setState( { fieldSwitchVal: '', fieldSwitch: '' } );
          cb( null, 'DataSaved' );
        }
      },
    );
  };

  toggleConfirmSaveModal = () => {
    const { confirmSaveModal } = this.state;
    this.setState( { confirmSaveModal: !confirmSaveModal } );
  };

  toggleProduct = ( id ) => {
    const { openedProducts } = this.state;
    const newOpenedProducts = [...openedProducts];
    const index = newOpenedProducts.indexOf( id );
    if ( index !== -1 ) {
      newOpenedProducts.splice( index, 1 );
    } else {
      newOpenedProducts.push( id );
    }
    this.setState( { openedProducts: newOpenedProducts } );
  };
  toggleConfirmRememberModal = () => {
    const { confirmRememberModal } = this.state;
    this.setState( { confirmRememberModal: !confirmRememberModal } );
  };
  calcPorcMargin = ( priceProduct, tradeSalePrice, field, type ) => {
    if (
      _.isNaN( priceProduct )
      || _.isNaN( tradeSalePrice )
      || tradeSalePrice === 0
      || priceProduct === 0
      || priceProduct === '0'
    ) return 0;
    const porcMargin = ( ( priceProduct - tradeSalePrice ) / priceProduct ) * 100;
    if ( type === 'service' ) {
      this.confirmLessFivePercentService[field] = porcMargin < 5 ? 1 : 0;
      // eslint-disable-next-line max-len
      this.boolConfirmLessFivePercentService = _.sum( _.values( this.confirmLessFivePercentService ) ) === 0;
    } else if ( type === 'bundle' ) {
      this.confirmLessFivePercentBundle[field] = porcMargin < 5 ? 1 : 0;
      // eslint-disable-next-line max-len
      this.boolConfirmLessFivePercentBundle = _.sum( _.values( this.confirmLessFivePercentBundle ) ) === 0;
    }
    return porcMargin;
  };
  selectTableServicesIcon = ( label ) => {
    if ( label === 'SERVICE_1' ) return 'tv';
    if ( label === 'SERVICE_2' ) return 'bookOpen';
    if ( label === 'SERVICE_3' ) return 'music';
    return 'services';
  };
  render() {
    const {
      services,
      bundles,
      confirmSaveModal,
      confirmExitModal,
      fieldSwitch,
      fieldSwitchVal,
      openedProducts,
      confirmRememberModal,
    } = this.state;
    const { user } = this.props;

    return (
      <>
        <HeadConfig
          breadcrumbsLinks={[
            {
              url: '/',
              name: 'HomeLink',
            },
            { name: 'DecisionDashboard-services' },
          ]}
        />
        <KotlerSectionHeader title="services" image={HeaderImage} />

        <Form
          initialValues={{ services, bundles }}
          onSubmit={this.submitForm}
          validate={( values ) => {
            const errors = {};
            _.map( values.services, ( item ) => {
              if ( item.tradeSalePrice > item.price ) {
                errors.lessOrEqual = 'less or equal';
              }
            } );

            _.map( values.bundles, ( item ) => {
              if ( item.tradeSalePrice > item.price ) {
                errors.lessOrEqual = 'less or equal';
              }
            } );
          }}
          mutators={{ ...arrayMutators }}
          render={( { handleSubmit, form } ) => (
            <form onSubmit={handleSubmit}>
              <Grid
                container
                spacing={1}
                className="kotler-table-principal-container justify-content-sm-between"
              >
                <Grid
                  item
                  xs={12}
                  md={
                    user
                    && user.activeSession
                    && user.activeSession.status === STATUS.READY
                      ? 9
                      : 12
                  }
                  lg={
                    user
                    && user.activeSession
                    && user.activeSession.status === STATUS.READY
                      ? 10
                      : 12
                  }
                >
                  <SectionInfoText title="help.service.snackbar" />
                </Grid>
                {user
                  && user.activeSession
                  && user.activeSession.status === STATUS.READY && (
                  <Grid
                    item
                    xs={12}
                    md="auto"
                    className="d-flex justify-content-end align-items-start"
                  >
                    <Button
                      variant="contained"
                      type="button"
                      color="primary"
                      style={{ minWidth: 192 }}
                      className=" text-white kotler-button-md mt-3   mt-lg-4"
                      component={Link}
                      to="/sessions/general/services/new-bundle"
                    >
                      <IntlMessages id="newBundle" />
                    </Button>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Typography variant="h6" style={{ fontWeight: 700 }}>
                    <IntlMessages id="services" />
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <div className="kotler-table-container pt-lg-3">
                    <Table size="small" className="kotler-table">
                      <TableHead>
                        <TableRow>
                          <TableCell style={{ width: 270 }}>
                            <IntlMessages id="service" />
                          </TableCell>
                          <TableCell align="right">
                            <HoverTitle id="monthlyCostSubscriptionSoldService">
                              <span>
                                <IntlMessages id="unitCostMonth" />
                              </span>
                            </HoverTitle>
                          </TableCell>
                          <TableCell align="right">
                            <HoverTitle id="monthlySubscriptionCompanySellsServicePublic">
                              <span>
                                <IntlMessages id="monthlyFee" />
                              </span>
                            </HoverTitle>
                          </TableCell>
                          <TableCell align="right">
                            <div>
                              <IntlMessages id="lastMonthSalesDollar" />
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <div>
                              <IntlMessages id="lastMonthSalesUnits" />
                            </div>
                          </TableCell>
                          <TableCell
                            align="center"
                            size="small"
                            padding="none"
                            style={{ width: '120px' }}
                          >
                            <HoverTitle id="activatesDeactivatesOfferingService">
                              <span className="pl-2">
                                <IntlMessages id="status" />
                              </span>
                            </HoverTitle>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <FieldArray name="services">
                          {( { fields } ) => fields.map( ( serviceField, productIndex ) => {
                            const service = services[productIndex];
                            if ( !service ) return null;
                            return [
                              <TableRow key={service.id}>
                                <TableCell>
                                  <div className=" ">
                                    <KotlerTableTitle
                                      iconClass="mx-0 mr-2"
                                      fontSize="1rem"
                                      icon={this.selectTableServicesIcon(
                                        service.serviceType.label,
                                      )}
                                      title={service.serviceType.name
                                        .toLowerCase()
                                        .replaceAll( ' ', '' )}
                                    />
                                  </div>
                                </TableCell>
                                <TableCell align="right">
                                  <HoverTitle id="monthlyCostSubscriptionSoldService">
                                    <span>
                                      {Util.formatCurrency( service.unitCost )}
                                    </span>
                                  </HoverTitle>
                                </TableCell>
                                <TableCell align="right">
                                  <HoverTitle id="monthlySubscriptionCompanySellsServicePublic">
                                    <span>
                                      {Util.formatCurrency( service.price )}
                                    </span>
                                  </HoverTitle>
                                </TableCell>
                                <TableCell align="right">
                                  {Util.formatCurrency(
                                    service.lastRoundSales,
                                  )}
                                </TableCell>
                                <TableCell align="right">
                                  {Util.formatNumber(
                                    service.lastRoundUnitSales,
                                  )}
                                </TableCell>
                                <TableCell align="center" padding="none">
                                  <div className="d-flex  align-items-center ">
                                    <Switch
                                      hover={{
                                        id:
                                            'activatesDeactivatesOfferingService',
                                      }}
                                      field={`${serviceField}.active`}
                                      marginContainer={false}
                                      disabled={
                                        user
                                          && user.activeSession
                                          && user.activeSession.status
                                            !== STATUS.READY
                                      }
                                      onChange={( checked ) => {
                                        this.setState( {
                                          confirmSaveModal: true,
                                          fieldSwitch: `${serviceField}.active`,
                                          fieldSwitchVal: checked,
                                        } );
                                      }}
                                    />
                                    <Typography
                                      variant="body1"
                                      style={{ fontWeight: 500 }}
                                    >
                                      {service.active ? (
                                        <IntlMessages id="lblActive" />
                                      ) : (
                                        <IntlMessages id="lblInactive" />
                                      )}
                                    </Typography>
                                  </div>
                                </TableCell>
                              </TableRow>,
                            ];
                          } )
                          }
                        </FieldArray>
                      </TableBody>
                    </Table>
                  </div>
                </Grid>
                <Grid item>
                  <Typography
                    variant="h6"
                    className="mt-3"
                    style={{ fontWeight: 700 }}
                  >
                    <IntlMessages id="bundles" />
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <div className="kotler-table-container  pt-lg-2">
                    <Table size="small" className="kotler-table">
                      <TableHead>
                        <TableRow>
                          <TableCell style={{ width: 270 }}>
                            <IntlMessages id="bundle" />
                          </TableCell>
                          <TableCell align="right" style={{ width: 120 }}>
                            <HoverTitle id="totalCostBundleCompany">
                              <span>
                                <IntlMessages id="unitCost" />
                              </span>
                            </HoverTitle>
                          </TableCell>
                          <TableCell align="right" style={{ width: 120 }}>
                            <HoverTitle id="monthlyBundleCostBuyers">
                              <span>
                                <IntlMessages id="monthlyFee" />
                              </span>
                            </HoverTitle>
                          </TableCell>
                          <TableCell align="right" style={{ width: 120 }}>
                            <HoverTitle id="numberInstallmentsBundle">
                              <span>
                                <IntlMessages id="totalFee" />
                              </span>
                            </HoverTitle>
                          </TableCell>
                          <TableCell align="right" style={{ width: 120 }}>
                            <HoverTitle id="totalPriceBundleConsumer">
                              <span>
                                <IntlMessages id="totalPrice" />
                              </span>
                            </HoverTitle>
                          </TableCell>
                          <TableCell align="right" style={{ width: 130 }}>
                            <IntlMessages id="lastMonthSalesDollar" />
                          </TableCell>
                          <TableCell align="right" style={{ width: 130 }}>
                            <IntlMessages id="lastMonthSalesUnits" />
                          </TableCell>
                          <TableCell align="center" style={{ width: 120 }}>
                            <HoverTitle id="activatesDeactivatesOfferingBundle">
                              <span className="pl-3">
                                <IntlMessages id="status" />
                              </span>
                            </HoverTitle>
                          </TableCell>
                          <TableCell>
                            {/* <div><IntlMessages id="BundleDetails" /></div> */}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <FieldArray name="bundles">
                          {( { fields } ) => fields.map( ( bundleField, bundleIndex ) => {
                            const bundle = bundles[bundleIndex];
                            if ( !bundle ) return null;
                            const openedProduct = openedProducts.indexOf( bundle.id ) !== -1;
                            return [
                              <TableRow key={bundle.id}>
                                <TableCell>
                                  <Typography
                                    variant="body1"
                                    style={{ color: '#09082C' }}
                                    className="text-uppercase font-weight-bold my-0"
                                  >
                                    {bundle.name}
                                  </Typography>
                                </TableCell>
                                <TableCell align="right">
                                  <HoverTitle id="totalCostBundleCompany">
                                    <span>
                                      {Util.formatCurrency( bundle.unitCost )}
                                    </span>
                                  </HoverTitle>
                                </TableCell>

                                <TableCell align="right">
                                  <HoverTitle id="monthlyBundleCostBuyers">
                                    <span>
                                      {Util.formatCurrency( bundle.monthlyFee )}
                                    </span>
                                  </HoverTitle>
                                </TableCell>
                                <TableCell align="right">
                                  <HoverTitle id="numberInstallmentsBundle">
                                    <span>
                                      {Util.formatNumber( bundle.totalFee )}
                                    </span>
                                  </HoverTitle>
                                </TableCell>
                                <TableCell align="right">
                                  <HoverTitle id="totalPriceBundleConsumer">
                                    <span>
                                      {Util.formatCurrency(
                                        Number(
                                          _.get(
                                            fields,
                                            `value[${bundleIndex}].monthlyFee`,
                                            0,
                                          ),
                                        ) * bundle.totalFee,
                                      )}
                                    </span>
                                  </HoverTitle>
                                </TableCell>
                                <TableCell align="right">
                                  {Util.formatCurrency( bundle.lastRoundSales )}
                                </TableCell>
                                <TableCell align="right">
                                  {Util.formatNumber(
                                    bundle.lastRoundUnitSales,
                                  )}
                                </TableCell>
                                <TableCell align="center">
                                  <div className="d-flex  align-items-center ">
                                    <Switch
                                      hover={{
                                        id:
                                            'activatesDeactivatesOfferingBundle',
                                      }}
                                      field={`${bundleField}.active`}
                                      marginContainer={false}
                                      disabled={
                                        user
                                          && user.activeSession
                                          && user.activeSession.status
                                            !== STATUS.READY
                                      }
                                      onChange={( checked ) => {
                                        this.setState( {
                                          confirmSaveModal: true,
                                          fieldSwitch: `${bundleField}.active`,
                                          fieldSwitchVal: checked,
                                        } );
                                      }}
                                    />
                                    <Typography
                                      variant="body1"
                                      style={{ fontWeight: 500 }}
                                    >
                                      {bundle.active ? (
                                        <IntlMessages id="lblActive" />
                                      ) : (
                                        <IntlMessages id="lblInactive" />
                                      )}
                                    </Typography>
                                  </div>
                                </TableCell>

                                <TableCell>
                                  <div className="d-flex justify-content-right">
                                    <div
                                      style={{
                                        border: '2px solid #999FA3',
                                        borderRadius: '2px',
                                      }}
                                      onClick={() => this.toggleProduct( bundle.id )
                                      }
                                    >
                                      <FeatherIcon
                                        className={
                                          openedProduct
                                            ? 'fa-rotate-180 '
                                            : ''
                                        }
                                        icon="chevronDown"
                                        color="#999FA3"
                                        size="1.5rem"
                                      />
                                    </div>
                                  </div>
                                </TableCell>
                              </TableRow>,
                              openedProduct ? (
                                <TableRow key={`${bundle.id}-detail`}>
                                  <TableCell
                                    style={{ backgroundColor: '#dedede' }}
                                  />
                                  <TableCell
                                    style={{ backgroundColor: '#dedede' }}
                                  />
                                  <TableCell
                                    style={{ backgroundColor: '#dedede' }}
                                  />
                                  <TableCell
                                    style={{ backgroundColor: '#dedede' }}
                                    colSpan={2}
                                  >
                                    <span>
                                      <IntlMessages id="product" />
:
                                    </span>
                                    <Badge color="primary" className="ml-2">
                                      {bundle.product.name}
                                    </Badge>
                                  </TableCell>
                                  <TableCell
                                    style={{ backgroundColor: '#dedede' }}
                                    colSpan={2}
                                  >
                                    <span>
                                      <IntlMessages id="service" />
:
                                    </span>
                                    <Badge color="primary" className="ml-2">
                                      {bundle.service.name}
                                    </Badge>
                                  </TableCell>
                                  <TableCell
                                    style={{ backgroundColor: '#dedede' }}
                                  />
                                  <TableCell
                                    style={{ backgroundColor: '#dedede' }}
                                  />
                                </TableRow>
                              ) : null,
                            ];
                          } )
                          }
                        </FieldArray>
                      </TableBody>
                    </Table>
                  </div>
                </Grid>
              </Grid>
              <ConfirmationDialog
                open={confirmRememberModal}
                subTitle="messageAfterActivatingService"
                confirmBtnText="ok"
                showCancel={false}
                closeOnClickOutside={false}
                onSubmit={() => {
                  this.toggleConfirmRememberModal();
                }}
              />
              <ConfirmationDialog
                open={confirmSaveModal}
                /*  title={( this.boolConfirmLessFivePercentService || !fieldSwitchVal ) ?
                  'confirmSave' : 'confirmSaveServiceAlertWithLessFivePercent'} */
                title="confirmSave"
                confirmBtnText="ok"
                showIcon
                type="save"
                closeBtnText="cancelBtn"
                onSubmit={() => {
                  this.toggleConfirmSaveModal();
                  // form.change( fieldSwitch, fieldSwitchVal );
                  handleSubmit();
                }}
                onClose={() => {
                  this.toggleConfirmSaveModal();
                  if ( fieldSwitch ) {
                    form.change( fieldSwitch, !fieldSwitchVal );
                  }
                }}
              />
            </form>
          )}
        />
        <ConfirmationDialog
          open={confirmExitModal}
          title="confirmExitWithOutSaving"
          subTitle="subtitleExitWithoutSaving"
          confirmBtnText="yesModalBtn"
          closeBtnText="cancelBtn"
          onSubmit={() => {
            this.toggleConfirmExitModal();
            this.setConfirmExitModal();
          }}
          onClose={this.toggleConfirmExitModal}
        />
      </>
    );
  }
}

const mapStateToProps = ( { user } ) => ( { user } );

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)( injectIntl( Service ) );
