import { Typography } from '@material-ui/core';
import React from 'react';
import IntlMessages from 'utils/IntlMessages';

export const DescriptionGraphText = ( { title, className, icon } ) => (
  <Typography
    className={className}
    style={{
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: '16px',
      color: '#999FA3',
      marginTop: '16px',
    }}
  >
    {icon}
    {' '}
    {title ? (
      <IntlMessages id={title} />
    ) : (
      <IntlMessages id="info.product.HelpText" />
    )}
  </Typography>
);
