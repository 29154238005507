import api from 'config/Api';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import SettingsActions from 'store/reducers/Settings';

const { toggleAlert } = SettingsActions;
const delete60DaysRounds = async () => {
  const res = await api.post( 'v2/job/delete/dated-sessions' );

  if ( !res.ok ) {
    throw new Error( res.errors || "Can't delete 60 days rounds" );
  }

  return res.ok;
};
export const useDelete60DaysRounds = () => {
  const dispatch = useDispatch();
  const mutation = useMutation(
    ['v2', 'maintenance', 'delete60DaysRounds'],
    () => delete60DaysRounds(),
    {
      onSuccess: () => {
        dispatch( toggleAlert( '60 days rounds deleted successfully', 'info' ) );
      },
      onError: ( error ) => {
        dispatch( toggleAlert( error.message, 'error' ) );
      },
    },
  );

  return mutation;
};
