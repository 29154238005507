import React from 'react';
import { connect } from 'react-redux';
import {
  Divider,
  FormHelperText,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import _ from 'lodash';
import async from 'async';

import SettingsActions from 'store/reducers/Settings';
import IntlMessages from 'utils/IntlMessages';
import { TextField, RadioButtons } from 'components/custom/FormElements';
import {
  composeValidators,
  greaterOrEqualValue,
  required,
} from 'config/InputErrors';
import ProductService from 'services/Product';
import ServiceService from 'services/Service';
import BundleService from 'services/Bundle';
import Util from 'utils/Util';
import HoverTitle from 'components/custom_v2/HoverTitle';
import HeaderImage from 'assets/images/kotler/sectionHeaders/services.jpeg';
import KotlerSectionHeader from 'components/custom/KoTlerSectionHeader';
import KotlerTableTitle from 'components/custom/KotlerTableTitle';
import HeadConfig from 'components/custom/HeadConfig';
import ConfirmationDialog from 'components/custom_v2/ConfirmationDialog';
import ConfirmCancelButtons from 'components/custom/ConfirmCancelButtons';

class NewBundle extends React.Component {
  state = {
    products: [],
    services: [],
    variables: [],
    confirmRememberModal: false,
    confirmationOpen: false,
    bundleCost: null,
  };
  price = 0;
  componentDidMount() {
    const { toggleAlert, user } = this.props;

    if ( !user.activeSession ) {
      return toggleAlert( 'ERROR' );
    }

    this.activeSession = user.activeSession;
    this.license = _.find( user.licenses, { session: { id: user.activeSession.id } } );
    if ( !this.license || !this.license.workGroup ) {
      return toggleAlert( 'ERROR' );
    }

    this.getData();
  }

  getData = () => {
    const { toggleAlert, toggleLoading } = this.props;

    toggleLoading( true );
    async.parallel(
      {
        services: ( cb ) => {
          ServiceService.getServicesV2( this.activeSession.id, {
            workgroup: this.license.workGroup.id,
            // 'filters[active]': 1,
          } ).then( ( response ) => {
            if ( !response.ok ) return cb( response.errors );
            cb( null, response.data.data );
          } );
        },
        products: ( cb ) => {
          ProductService.getProducts( this.activeSession.id, {
            workgroup: this.license.workGroup.id,
            'filters[inStatus]': ['READY'],
            'filters[active]': 1,
          } ).then( ( response ) => {
            if ( !response.ok ) return cb( response.errors );
            cb( null, response.data.data );
          } );
        },
      },
      ( error, results ) => {
        toggleLoading( false );
        if ( error ) return toggleAlert( error );
        this.setState( results );
      },
    );
  };
  getBundleCost = async ( serviceId, productId ) => {
    this.setState( { bundleCost: null } );
    const { toggleAlert, toggleLoading } = this.props;
    toggleLoading( true );
    BundleService.getBundleCost( serviceId, productId ).then( ( response ) => {
      toggleLoading( false );
      if ( !response.ok ) {
        if ( response.errors ) {
          return toggleAlert( <IntlMessages id={response.errors} /> );
        }
        return;
      }
      if ( response.data ) this.setState( { bundleCost: response.data.cost } );
    } );
  };
  submitForm = ( data ) => {
    const { toggleAlert, toggleLoading } = this.props;
    const productId = data.productService
      ? data.productService.split( '_' )[0]
      : null;
    const serviceId = data.productService
      ? data.productService.split( '_' )[1]
      : null;

    toggleLoading( true );
    const dataToSend = {
      workgroup: this.license.workGroup.id,
      name: data.name
        .normalize( 'NFD' )
        .replace( /[\u0300-\u036f]/g, '' )
        .replace( /[^a-zA-Z0-9 ]/g, '' ),
      price: data.monthlyFee,
      monthlyFee: data.monthlyFee,
      product: productId,
      service: serviceId,
    };

    BundleService.saveBundleV2( this.activeSession.id, dataToSend ).then(
      ( response ) => {
        toggleLoading( false );
        if ( !response.ok ) {
          if ( response.errors && response.errors === 'Service deactivated.' ) {
            return toggleAlert( <IntlMessages id={response.errors} /> );
          }
          return toggleAlert( response.errors );
        }

        toggleAlert( 'dataSaved', 'info' );
        this.toggleConfirmRememberModal();
        this.toggleConfirmationModal();
      },
    );
  };

  toggleConfirmationModal = () => {
    const { confirmationOpen } = this.state;

    this.setState( { confirmationOpen: !confirmationOpen } );
  };
  selectTableServicesIcon = ( label ) => {
    if ( label === 'SERVICE_1' ) return 'tv';
    if ( label === 'SERVICE_2' ) return 'bookOpen';
    if ( label === 'SERVICE_3' ) return 'music';
    return 'services';
  };
  toggleConfirmRememberModal = () => {
    const { confirmRememberModal } = this.state;
    this.setState( { confirmRememberModal: !confirmRememberModal } );
  };
  render() {
    const {
      products,
      services,
      confirmationOpen,
      variables,
      confirmRememberModal,
      bundleCost,
    } = this.state;
    const { history } = this.props;
    return (
      <Form
        validate={( values ) => {
          const errors = {};
          if ( !values.productService ) {
            errors.productServiceError = required( values.productService );
          }
          return errors;
        }}
        onSubmit={this.toggleConfirmationModal}
        mutators={{ ...arrayMutators }}
        render={( { handleSubmit, values, submitFailed, errors } ) => {
          const productId = values && values.productService
            ? values.productService.split( '_' )[0]
            : null;
          const productObj = _.find( products, { id: productId } );
          const serviceId = values && values.productService
            ? values.productService.split( '_' )[1]
            : null;
          const serviceObj = _.find( services, { id: serviceId } );

          return (
            <>
              <HeadConfig
                breadcrumbsLinks={[
                  {
                    url: '/',
                    name: 'HomeLink',
                  },
                  {
                    url: '/sessions/general/services',
                    name: 'DecisionDashboard-services',
                  },
                  { name: 'newBundle' },
                ]}
              />
              <KotlerSectionHeader
                title="services"
                image={HeaderImage}
                titleSubmit="saveBundle"
                titleComplement="newBundle"
                onSubmit={handleSubmit}
                titleCancel="cancelBtn"
                cancelTo="/sessions/general/services"
              />
              <form onSubmit={handleSubmit}>
                <Grid
                  container
                  spacing={2}
                  className="justify-content-center justify-content-sm-start"
                >
                  <Grid item xs={12} className="mt-4 mb-1">
                    <Typography
                      variant="h6"
                      style={{ fontWeight: 700, fontSize: '1.12rem' }}
                      color="textPrimary"
                    >
                      <IntlMessages id="newProductTitleGeneral" />
                    </Typography>
                  </Grid>
                  <Grid item sx={12} sm={6}>
                    <TextField
                      labelClass="kotler-text-field-label"
                      field="name"
                      label="bundleName"
                      validate={required}
                    />
                  </Grid>
                </Grid>
                <Divider className="mt-3 mb-3  rounded-pill" />

                <Grid container>
                  <Grid item xs={12} className="my-2">
                    <Typography
                      variant="h6"
                      style={{ fontWeight: 600, fontSize: '1.12rem' }}
                      color="textPrimary"
                    >
                      <IntlMessages id="selectProductServiceCombination" />
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="kotler-table-container  pt-lg-2">
                      <Table size="small" className="kotler-table">
                        <TableHead>
                          <TableRow>
                            <TableCell />
                            {_.map( services, service => (
                              <TableCell key={service.id}>
                                <KotlerTableTitle
                                  icon={this.selectTableServicesIcon(
                                    service.serviceType.label,
                                  )}
                                  fontSize="1rem"
                                  title={service.name.split( ' - ' )[1]}
                                  titleValues={{ code: service.name.split( ' - ' )[0] }}
                                />
                              </TableCell>
                            ) )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {_.map( products, product => (
                            <TableRow key={product.id}>
                              <TableCell>
                                <Typography
                                  variant="body1"
                                  style={{ fontWeight: 500 }}
                                >
                                  {product.name}
                                </Typography>
                              </TableCell>
                              {_.map( services, service => (
                                <TableCell key={service.id}>
                                  <RadioButtons
                                    field="productService"
                                    radioClassName="ml-1 p-0"
                                    options={[
                                      { id: `${product.id}_${service.id}` },
                                    ]}
                                    onChange={() => {
                                      variables.totalFee = product.category.totalFee;
                                      this.getBundleCost(
                                        service.id,
                                        product.id,
                                      );
                                    }}
                                  />
                                </TableCell>
                              ) )}
                            </TableRow>
                          ) )}
                        </TableBody>
                      </Table>
                    </div>
                  </Grid>
                  {submitFailed && errors && errors.productServiceError ? (
                    <Grid xs={12}>
                      <FormHelperText className="Mui-error text-center p-3">
                        <Typography variant="body1">
                          <IntlMessages id={errors.productServiceError} />
                        </Typography>
                      </FormHelperText>
                    </Grid>
                  ) : null}
                </Grid>

                <Grid
                  container
                  spacing={2}
                  className="justify-content-around  mt-4"
                >
                  <Grid item sx={12} sm={4} md={5}>
                    <HoverTitle id="monthlyBundleCostBuyers">
                      <TextField
                        labelClass="kotler-text-field-label"
                        field="monthlyFee"
                        label="bundleMonthlyFee"
                        type="number"
                        inputProps={{ className: 'text-right' }}
                        validate={composeValidators(
                          required,
                          greaterOrEqualValue( 0 ),
                        )}
                      />
                    </HoverTitle>
                  </Grid>

                  <Grid item sx={12} sm={6} md={5}>
                    <HoverTitle id="numberInstallmentsBundle">
                      <TextField
                        className="kotler-text-field-gray"
                        labelClass="kotler-text-field-label"
                        field="totalFee"
                        label="totalFee"
                        disabled
                        type="number"
                        inputProps={{ className: 'text-right' }}
                        value={variables.totalFee}
                      />
                    </HoverTitle>
                  </Grid>
                  <Grid item sx={12} sm={4} md={5}>
                    <HoverTitle id="totalPriceBundleConsumer">
                      <TextField
                        className="kotler-text-field-gray"
                        labelClass="kotler-text-field-label"
                        field="totalPrice"
                        label="totalPrice"
                        type="number"
                        disabled
                        inputProps={{ className: 'text-right' }}
                        // eslint-disable-next-line max-len
                        value={Util.formatCurrency(
                          variables.totalFee * values.monthlyFee,
                        )}
                      />
                    </HoverTitle>
                  </Grid>
                  <Grid item sx={12} sm={4} md={5}>
                    <TextField
                      className="kotler-text-field-gray"
                      labelClass="kotler-text-field-label"
                      field="bundleCost"
                      label="bundleCost"
                      type="number"
                      disabled
                      inputProps={{ className: 'text-right' }}
                      // eslint-disable-next-line max-len
                      value={Util.formatCurrency(
                        !bundleCost && bundleCost !== 0
                          ? null
                          : bundleCost / Number( variables.totalFee ),
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <ConfirmCancelButtons
                    titleSubmit="saveBundle"
                    titleComplement="newBundle"
                    onSubmit={handleSubmit}
                    titleCancel="cancelBtn"
                    cancelTo="/sessions/general/services"
                  />
                </Grid>
                <ConfirmationDialog
                  open={confirmationOpen}
                  type="save"
                  title="activateBundleQ"
                  confirmBtnText="ok"
                  closeBtnText="cancelBtn"
                  onClose={this.toggleConfirmationModal}
                  onSubmit={() => this.submitForm( values )}
                >
                  <>
                    <p className="label-font-sm mb-1">
                      <b>
                        <IntlMessages id="name" />
                      </b>
                      {' : '}
                      {values.name}
                    </p>
                    <p className="label-font-sm mb-1">
                      <b>
                        <IntlMessages id="product" />
                      </b>
                      {' : '}
                      {productObj ? productObj.name : ''}
                    </p>
                    <p className="label-font-sm mb-1">
                      <b>
                        <IntlMessages id="service" />
                      </b>
                      {' : '}
                      {serviceObj ? serviceObj.name : ''}
                    </p>

                    <p className="label-font-sm">
                      <b>
                        <IntlMessages id="fee" />
                      </b>
                      {' : '}
                      {Util.formatCurrency( values.monthlyFee )}
                    </p>
                  </>
                </ConfirmationDialog>
                <ConfirmationDialog
                  open={confirmRememberModal}
                  subTitle="messageAfterActivatingNewBundle"
                  confirmBtnText="ok"
                  showCancel={false}
                  closeOnClickOutside={false}
                  onSubmit={() => {
                    this.toggleConfirmRememberModal();
                    history.push( '/sessions/general/services' );
                  }}
                  onClose={() => {
                    this.toggleconfirmRememberModal();
                    history.push( '/sessions/general/services' );
                  }}
                />
                {/*    <SweetAlert
                  show={confirmationOpen}
                  warning
                  showCancel
                  confirmBtnText={<IntlMessages id="yes" />}
                  confirmBtnBsStyle="danger"
                  cancelBtnBsStyle="default"
                  title={(

                  )}
                  onConfirm={(}
                  onCancel={this.toggleConfirmationModal}
                /> */}
              </form>
            </>
          );
        }}
      />
    );
  }
}

const mapStateToProps = ( { user } ) => ( { user } );

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)( NewBundle );
