import { Button } from '@material-ui/core';
import React, { useState } from 'react';
import IntlMessages from 'utils/IntlMessages';
import ConfirmationDialog from 'components/custom_v2/ConfirmationDialog';
import { useDispatch } from 'react-redux';
import SettingsActions from 'store/reducers/Settings';
import { useDelete60DaysRounds } from '../hooks/delete60DaysRounds';

const { toggleLoading } = SettingsActions;
const BtnDelete60DaysRounds = () => {
  const dispatch = useDispatch();
  const { mutateAsync } = useDelete60DaysRounds();
  const [open, setOpen] = useState( false );
  const onSubmit = async () => {
    dispatch( toggleLoading( true ) );
    try {
      await mutateAsync();
    } catch ( e ) {
      // eslint-disable-next-line no-console
      console.error( e );
    }

    dispatch( toggleLoading( false ) );
  };
  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        onClick={() => setOpen( !open )}
      >
        <IntlMessages id="button.deleteRounds" />
      </Button>
      <ConfirmationDialog
        open={open}
        title="confirmationMessage.deleteRounds"
        confirmBtnText="ok"
        onSubmit={onSubmit}
        onClose={() => setOpen( false )}
      />
    </>
  );
};

export default BtnDelete60DaysRounds;
