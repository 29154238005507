import KotlerSectionHeader from 'components/custom/KoTlerSectionHeader';
import React from 'react';
import Image from 'assets/images/kotler/sectionHeaders/register.jpeg';
import { Container, Grid } from '@material-ui/core';
import CardBox from 'components/custom_v2/CardBox';
import BtnDelete60DaysRounds from 'modules/maintenance/components/BtnDelete60DaysRounds';
import KotlerCardHeader from 'components/custom/KotlerCardHeader';

const MaintancePage = () => (
  <div className="h-100  d-flex flex-column ">
    <KotlerSectionHeader title="maintenance" image={Image} />
    <Container className="d-flex flex-grow-1 ">
      <Grid container className="pt-4 h-100 ">
        <Grid item xs={12} md={4} lg={3} className=" ">
          <CardBox
            header={
              <KotlerCardHeader title="title.deleteRounds" minHeight="0xp" />
            }
            childrenStyle="px-2"
          >
            <BtnDelete60DaysRounds />
          </CardBox>
        </Grid>
      </Grid>
    </Container>
  </div>
);

export default MaintancePage;
